<template>
  <div class="placeholder">
    <div class="icon">
      <i :class="this.windowData.Icon"></i>
    </div>
    <div class="content">
      <h5>{{ this.windowData.Title }}</h5>
      <p>
        {{ this.windowData.Description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeWindow() {
      if (this.$store.state.subWindow.output) {
        return this.$store.state.subWindow
      }

      return this.$store.state.window
    },
    windowData() {
      return this.activeWindow.output?.Data
    },
  },
}
</script>

<style scoped>
.placeholder {
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 70px;
}

.placeholder > .icon {
  font-size: 150px;
  color: #565656;

  width: auto;
}

.placeholder > .content {
  margin-top: 10px;
}
</style>
